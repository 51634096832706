import { DateRange } from '@/shared/DateTime';
import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import { CONSIGNMENT_TRACK_ETA_FILTER_UPDATE } from './types';

instrumentation.on(CONSIGNMENT_TRACK_ETA_FILTER_UPDATE, ({ eta }: { eta: DateRange | 'all' | 'overdue' }) => {
  userMonitor.event('ETA filter updated', {
    track: {
      eta: eta.toString(),
    },
  });

  if (eta === 'overdue') {
    FullStory.event('Overdue selected', {});
  }
});
