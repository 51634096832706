import SpanEvent from './SpanEvent';

/**
 * VueInstrumentation
 *
 * An instrumentation event system which allows for consumers to create point in time events, and
 * spans which represent events that occur over a period of time. Other consumers can subscribe to
 * named events, and specify a callback to be run when each event or span ends.
 * Event subscribers are typically used to gather information from application events that can be
 * sent to logging and monitoring tools, keeping this code separate from the core business logic.
 */
export default class VueInstrumentation {
  constructor() {
    this.subscribers = {};
  }

  /**
   * Register an instrumentation module as a subscriber to a specific, named event. Creating an
   * instrumentation event on its own does not send that event to analytics or metrics tools.
   *
   * Components and views should simply emit instrumentation events about anything that might be
   * useful to measure. The job of packaging these events up and shipping them to specific tools
   * should be performed in instrumentation modules.
   *
   * @param name The event name to subscribe to.
   * @param callback A callback function with a payload argument, that will be used to process the
   * event and ship it to an external tool.
   */
  on(name, callback) {
    if (!name) {
      // throw new error here as logger is not instantiated when instrumentation events are registered
      throw new Error('Instrumentation: event name not provided');
    }
    if (!(name in this.subscribers)) {
      this.subscribers[name] = [];
    }
    this.subscribers[name].push(callback);
  }

  /**
   * Helper method to create event subscribers for span ':before' events.
   *
   * @param name The event name to subscribe to.
   * @param callback A callback function with a payload argument, that will be used to process the
   * event and ship it to an external tool.
   */
  before(name, callback) {
    this.on(`${name}:before`, callback);
  }

  /**
   * Remove an event subscriber;
   * @param name The event name.
   * @param callback The subscribed callback.
   */
  off(name, callback) {
    const callbackIndex = this.subscribers[name] && this.subscribers[name].indexOf(callback);
    if (callbackIndex !== false && callbackIndex !== -1) {
      this.subscribers[name].splice(callbackIndex, 1);
    } else {
      throw new Error(`Callback not registered for event '${name}`);
    }

    // If no callbacks remain for the event, remove it from subscribers
    if (this.subscribers[name].length === 0) {
      delete this.subscribers[name];
    }
  }

  /**
   * Clear all subscribers.
   */
  offAll() {
    this.subscribers = {};
  }

  /**
   * An instrumentation event is an immediate event with a name, and an optional payload of
   * contextual data (identifiers, values, etc) to differentiate it from other similar events.
   *
   * Instrumentation modules must subscribe to an event for it to be recorded in an external tool.
   *
   * @param name String The event name. This is used by instrumentation modules to "subscribe" to
   * the event.
   * @param payload Object The contextual data or values associated with the event.
   * @returns Object Collected return values from all subscribed events.
   */
  event(name, payload = {}) {
    // logger.debug(`instrumentation.event > ${name}`, payload);
    if (!name) {
      logger.error('Instrumentation: event name not provided');
      return {};
    }
    // No subscribers, exit early
    if (!(name in this.subscribers)) {
      // logger.debug(`Event: ${name} has no registered subscribers`);
      return {};
    }

    // Allow every subscriber to return a modified payload
    try {
      return this.subscribers[name].reduce(
        (collected, callback) => ({ ...collected, ...(callback(payload) || {}) }),
        {},
      );
    } catch (error) {
      logger.error(`${name} subscriber threw an error`, { error });
    }
    return {};
  }

  /**
   * An instrumentation span represents a process that has a start and an end, typically where
   * the duration should be measured. Spans can be used to measure API request/response times, or
   * user processes such as entering and exiting a field.
   * When a span is created, it returns an object with an `end` method. If the `end` method is not
   * called to signal the span has completed, *it will not trigger an event*.

   * Note: In some instrumentation systems, a span also wraps around events and spans that occur
   * inside of it, representing a hierarchy of calls. However, in this system it is just a named
   * event with a start and an end.
   * @param name String The span event name.
   * @param createPayload Optional object containing data from when the span began.
   * @returns SpanEvent The span object containing an `end` callback.
   */
  span(name, createPayload = {}) {
    return new SpanEvent(name, createPayload, this);
  }
}
