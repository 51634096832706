import { Temporal } from '@js-temporal/polyfill';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { ref, unref, watch } from 'vue';

import { plainDateNow } from '@/shared/DateTime/helpers';
import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';
import { PrintClientStatus } from '@/shared/models';

import userMonitor from '@App/instrumentation/userMonitor';
import getAutoprintDefaultSelection from '@App/modules/AutoPrint/domain/getAutoprintDefaultSelection';

import { getConsignmentStatus } from '@Consignment/helpers';
import { ConsignmentInstrumentation } from '@Consignment/instrumentation/types';
import { types as ConsignmentForm, ConsignmentFormQuotes } from '@Consignment/store/ConsignmentFormStore';

import './enquiry';
import './serviceSelection';


let quoteInteractions;
let quoteWatcherStop;
instrumentation.before(ConsignmentInstrumentation.CONSIGNMENT_CREATED, ({ component }) => {
  // TODO vue3 refactor. this needs to be tested once create con page is functional
  if (quoteWatcherStop) quoteWatcherStop();

  quoteInteractions = ref(0);
  quoteWatcherStop = watch(
    () => component.consignmentFormData.quoteId,
    () => {
      // TODO check with product this should increment when quoteId is null - line items change
      quoteInteractions.value += 1;
      logger.debug('quote updated', { quoteInteractions });
    },
  );

  return {
    defaults: { ...component.consignmentFormData },
  };
});

function quoteContextFromComponent(component) {
  const quotes = component.$store.getters[`${component.formStoreName}/${ConsignmentFormQuotes.getters.getQuotes}`];
  const sortedQuotes = [...quotes].sort((a, b) => a.total - b.total);
  const selectedQuote = quotes.find(quote => quote.id === component.consignmentFormData.quoteId);

  if (!selectedQuote) {
    return {
      cheapest: false,
      most_expensive: false,
      difference_from_cheapest: undefined,
      quotes_interactions: undefined,
      unavailable_pricing: undefined,
      agreed_service_changed: undefined,
    };
  }

  const selectedQuoteWithoutPrice = !!selectedQuote.evaluation.unavailablePricing;
  const quotesContext = {
    // @context.quotes.cheapest (facet)
    cheapest: !selectedQuoteWithoutPrice ? !sortedQuotes.find(quote => quote.total < selectedQuote.total) : false,
    // @context.quotes.most_expensive (facet)
    most_expensive: !selectedQuoteWithoutPrice ? !sortedQuotes.find(quote => quote.total > selectedQuote.total) : false,
    difference_from_cheapest: !selectedQuoteWithoutPrice ? sortedQuotes[0].total - selectedQuote.total : undefined,
    // @context.quotes.quotes_interactions (measure)
    quotes_interactions: unref(quoteInteractions),
    // @context.quotes.unavailable_pricing (facet)
    unavailable_pricing: selectedQuoteWithoutPrice,
  };

  const previousQuote = component.$store.getters[`${component.formStoreName}/${ConsignmentForm.getters.getPreviousQuote}`];

  if (previousQuote) {
    // @context.quotes.agreed_service_changed (facet)
    quotesContext.agreed_service_changed = (selectedQuote.agreedServiceId === previousQuote.agreedServiceId);
  }

  const order = component.$store.getters[`${component.formStoreName}/${ConsignmentForm.getters.getOrder}`];

  if (order && order.agreedService) {
    // @context.quotes.service_from_order_changed
    quotesContext.service_from_order_changed = (selectedQuote.agreedServiceId !== order.agreedService.id);
  }

  return quotesContext;
}

function addresseeChanges(before, after) {
  return {
    // @consignment.changed.*.contact_name
    contact_name: before.contactName !== after.contactName,
    // @consignment.changed.*.contact_phone
    contact_phone: before.contactPhone !== after.contactPhone,
    // @consignment.changed.*.contact_emails
    contact_email: before.contactEmail !== after.contactEmail,
    // @consignment.changed.*.line2
    line2: before.line2 !== after.line2,
    // Only include these fields if they're set in the data
    // @consignment.changed.*.special_instructions
    ...after.specialInstructions && { special_instructions: before.specialInstructions !== after.specialInstructions },
  };
}

function consignmentChangedKeys(current, initial) {
  return {
    // @context.consignment.changed.dispatch_date (Facet)
    dispatch_date: initial.dispatchDate !== current.dispatchDate,
    // @context.consignment.changed.references (Facet)
    references: !isEqual(sortBy(initial.references), sortBy(current.references)),
    billing: {
      // @consignment.changed.billing.cost_centre
      cost_centre: (!!current.costCenter || !!initial.costCenter) && initial.costCenter !== current.costCenter,
      // @consignment.changed.billing.payer_account
      payer_account: (!!current.payerAccount || !!initial.payerAccount) && initial.payerAccount !== current.payerAccount,
    },
    // @consignment.changed.receiver.* (Facets)
    receiver: addresseeChanges(initial.receiver, current.receiver),
    // @consignment.changed.sender.* (Facets)
    sender: addresseeChanges(initial.sender, current.sender),
    lineItems: {
      // @context.consignment.changed.lineItems.added (Measure)
      added: current.lineItems.filter(item => !item.id).length,
      // @context.consignment.changed.lineItems.updated (Measure)
      updated: current.lineItems.filter(item => !!item.id).reduce((total, item) => {
        if (item.quantity !== find(initial.lineItems, { id: item.id }).quantity) return total + 1;
        return total;
      }, 0),
      // @context.consignment.changed.lineItems.removed (Measure)
      removed: differenceBy(initial.lineItems, current.lineItems, 'id').length,
    },
  };
}

/**
 * @param {?PrintConfig|null} printConfig
 * @param {?PrintClientDetails} printClientDetails
 * @param autoprintPreference
 */
const getAutoprintInstrumentationContext = (printConfig, printClientDetails, autoprintPreference) => {
  const autoprintDefaults = getAutoprintDefaultSelection(printConfig, printClientDetails?.status);

  return {
    clientConfig: {
      enabled: printConfig?.enabled || false,
      defaultConnotePrinter: {
        enabled: printConfig?.defaultConnotePrinter.enabled || false,
        id: printConfig?.defaultConnotePrinter.id,
      },
      defaultLabelPrinter: {
        enabled: printConfig?.defaultLabelPrinter.enabled || false,
        id: printConfig?.defaultLabelPrinter.id,
      },
    },
    // @context.consignment.autoprint.clientStatus (facet)
    clientStatus: printClientDetails?.status,
    // @context.consignment.autoprint.clientRunning (facet)
    clientRunning: printClientDetails?.status === PrintClientStatus.running, // derived information
    // @context.consignment.autoprint.printLabelsDefault (facet)
    printLabelsDefault: autoprintDefaults.labels,
    // @context.consignment.autoprint.labelsPrinted (facet)
    labelsPrinted: autoprintPreference.labels,
    // @context.consignment.autoprint.labelsPrintCopies (Measure)
    labelsPrintCopies: autoprintPreference.labelCopies,
    // @context.consignment.autoprint.printConnoteDefault (facet)
    printConnoteDefault: autoprintDefaults.connote,
    // @context.consignment.autoprint.connotesPrinted (facet)
    connotesPrinted: autoprintPreference.connote,
  };
};

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CREATED, ({ component, consignment, defaults, duration }) => {
  const today = plainDateNow();
  const dispatchDate = Temporal.PlainDate.from(consignment.dispatchDate);
  const diff = today.until(dispatchDate);

  const receiverAddressee = component.consignmentFormData.receiver;
  const senderAddressee = component.consignmentFormData.sender;

  // Collect user metrics about how consignments are created, what gets used and changed
  const consignmentContext = {
    changed: consignmentChangedKeys(consignment, defaults),
    // @consignment.dispatch_delta_days (Measure)
    dispatch_delta_days: diff.total('days'),
    items: {
      // @consignment.items.with_description (Measure)
      with_description: consignment.lineItems.filter(item => !!item.description).length,
      // @consignment.items.with_reference (Measure)
      with_reference: consignment.lineItems.filter(item => !!item.reference).length,
      // @consignment.items.with_dg (Measure)
      with_dg: consignment.lineItems.filter(item => item.dangerousGoods && item.dangerousGoods.length).length,
    },
    // @consignment.reference_count (Measure)
    reference_count: consignment.references.length || 0,
    // @consignment.type (Facets)
    type: consignment.type,
    deliveryTimeSlot: {
      // @context.consignment.deliveryTimeSlot.selected (Facet)
      selected: consignment.receiver.deliveryTimeSlot?.requiresDTS || false,
      // @context.consignment.deliveryTimeSlot.dateRangeDays (Measure)
      dateRangeDays: consignment.receiver.deliveryTimeSlot?.requiresDTS
        ? consignment.receiver.deliveryTimeSlot.slot.dateRange[0]
          .until(consignment.receiver.deliveryTimeSlot.slot.dateRange[1] || consignment.receiver.deliveryTimeSlot.slot.dateRange[0])
          .days
        : undefined,
      // @context.consignment.deliveryTimeSlot.timeRangeMinutes
      timeRangeMinutes: consignment.receiver.deliveryTimeSlot?.requiresDTS
        ? consignment.receiver.deliveryTimeSlot.slot.timeRange
          .duration
          .minutes
        : undefined,
    },
    // @context.consignment.isConvertedEstimate
    isConvertedEstimate: !!consignment.estimateId,
    // @context.consignment.isConvertedOrder
    isConvertedOrder: !!consignment.orderId,
    autoprint: getAutoprintInstrumentationContext(
      component.autoprintConfig.config,
      component.autoprintConfig.client,
      component.autoprintPreference,
    ),
  };

  // @context.consignment.has_dg
  consignmentContext.has_dg = consignmentContext.items.with_dg.length > 0;

  consignmentContext.sender = {
    address_book_entry: !!senderAddressee.addressBookEntryId,
  };
  consignmentContext.receiver = {
    address_book_entry: !!receiverAddressee.addressBookEntryId,
  };

  if (consignment.pallets?.transferType) {
    consignmentContext.pallets = consignment.pallets?.transferType ? {
      // @context.consignment.pallets.transferType
      transferType: consignment.pallets.transferType,
      // @context.consignment.pallets.transferTypeOverridden
      transferTypeOverridden: undefined, // todo: implement this
      // @context.consignment.pallets.palletCount.(chep|loscam|other)
      palletCount: consignment.lineItems.reduce((acc, lineItem) => {
        Object.entries(lineItem.pallets || {}).forEach(([lender, count]) => {
          acc[lender] = (acc[lender] || 0) + count;
        });
        return acc;
      }, {}),
      // @context.consignment.pallets.docketCount.(chep|loscam|other)
      docketCount: Object.entries(consignment.pallets?.docketNumbers || {}).reduce((acc, [lender, docketNumbers]) => ({
        ...acc,
        [lender]: docketNumbers?.length || 0,
      }), {}),
    } : undefined;
  }

  // TODO Always include address_type even for Site addresses by saving it in ConsignmentFormStore
  if (typeof receiverAddressee.isEstimate !== 'undefined') {
    // @consignment.receiver.address_type
    consignmentContext.receiver.address_type = (receiverAddressee.isEstimate) ? 'estimate' : 'suggestion';
  }
  if (typeof senderAddressee.isEstimate !== 'undefined') {
    // @consignment.sender.address_type
    consignmentContext.sender.address_type = (senderAddressee.isEstimate) ? 'estimate' : 'suggestion';
  }

  const quotesContext = quoteContextFromComponent(component);

  userMonitor.event('consignment created', {
    duration,
    consignment: consignmentContext,
    quotes: quotesContext,
  });

  FullStory.event('consignment created', {
    consignmentId: consignment.id,
    requiresDTS: !!consignment.receiver.deliveryTimeSlot?.requiresDTS,
    hasPallets: !!consignmentContext.pallets,
    hasDGs: consignmentContext.items.with_dgs > 0,
  });
});

instrumentation.before(ConsignmentInstrumentation.CONSIGNMENT_UPDATED, payload => payload);
instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_UPDATED, ({
  component,
  initial,
  updated,
  consolidation,
  labelOptions,
}) => {
  userMonitor.event('consignment updated', {
    consignment: {
      // @context.consignment.isConsolidatedFromOrder
      isConsolidatedFromOrder: !!updated.orderId,
      changed: consignmentChangedKeys(updated, initial),
      // @context.consignment.consolidation (Facet)
      consolidation,
      autoprint: getAutoprintInstrumentationContext(
        component.autoprintConfig.config,
        component.autoprintConfig.client,
        component.autoprintPreference,
      ),
    },
    quotes: quoteContextFromComponent(component),
    // @context.new_labels_printed (Facet)
    new_labels_printed: !!labelOptions.newItemsOnly,
  });

  FullStory.event('consignment updated', {
    consolidation,
    newLabelsPrinted: !!labelOptions.newItemsOnly,
  });
});

instrumentation.on(ConsignmentInstrumentation.ADDRESS_MODE_CHANGE, ({ component }) => {
  FullStory.event('address mode change', {
    mode: component.isManualMode ? 'estimate' : 'suggestion',
  });
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_MISSED, ({
  consignment,
  suggestions,
  receiverPrevented,
}) => {
  userMonitor.event('consignment consolidation opportunity missed', {
    consignment: {
      type: consignment.type,
    },
    suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
    // @context.receiver_prevented (facet)
    receiver_prevented: receiverPrevented,
  });
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_SUGGESTIONS_PRESENTED, ({
  consignment,
  suggestions,
}) => {
  userMonitor.event('consignment consolidation suggestions presented', {
    consignment: {
      type: consignment.type,
      // @context.consignment.isConvertedEstimate
      isConvertedEstimate: !!consignment.estimateId,
      // @context.consignment.isConvertedOrder
      isConvertedOrder: !!consignment.orderId,
    },
    suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
  });
  FullStory.event('consignment consolidation suggestions presented', {
    consignmentType: consignment.type,
    suggestedConsignmentIds: suggestions.map(suggestion => suggestion.id),
  });
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_DETAILS_EXPANDED, ({ consignment }) => {
  const consignmentStatus = getConsignmentStatus({ consignment }).label;

  userMonitor.event('consignment details expanded', {
    consignment: {
      // @context.consignment.id
      id: consignment.id,
      // @context.consignment.status
      status: consignmentStatus,
      // @context.consignment.issues
      issues: Object.keys(consignment.issues || {}).length,
    },
  });

  FullStory.event('consignment details expanded', {
    consignmentId: consignment.id,
    consignmentStatus,
    consignmentIssues: Object.keys(consignment.issues || {}).length,
  }, {
    consignmentIssues: 'int',
  });
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_REJECTED, ({
  consignment,
  suggestions,
}) => {
  userMonitor.event('consignment consolidation opportunity rejected', {
    consignment: {
      id: consignment.id,
      type: consignment.type,
      // @context.consignment.isConvertedEstimate
      isConvertedEstimate: !!consignment.estimateId,
       // @context.consignment.isConvertedOrder
       isConvertedOrder: !!consignment.orderId,
    },
    suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
  });
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_DISCARDED, () => {
  userMonitor.event('consignment consolidation discarded');
  FullStory.event('consignment consolidation discarded');
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_AUTOPRINT_LABEL_COPIES_UPDATED, ({ labelCopies }) => {
  userMonitor.event('consignment autoprint label copies updated', {
    autoprint: {
      // @context.autoprint.labelCopies
      labelCopies,
    },
  });
  FullStory.event('consignment autoprint label copies updated');
});

instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_PRESELECTED_SERVICE_QUOTE_UPDATED, ({
  consignmentId,
  quote,
}) => {
  userMonitor.event('consignment preselected service quote updated', {
    consignment: {
      id: consignmentId,
    },
    quoteId: quote.id,
    // @context.quoteNotSelectable
    quoteNotSelectable: !quote.selectable,
    // @context.quoteUnrated
    quoteUnrated: quote.selectable && !quote.recommended,
  });
  FullStory.event('consignment preselected service quote updated');
});
